.navbar-nav {
  a {
    position: relative;
    text-decoration: none;
    font-weight: 600;
    color: #6c757d;
    letter-spacing: 1px;

    &:hover {
      color: #000;
    }

    @media (min-width: 767.95px) {
      &:not(:last-child) {
        margin-right: 25px;
      }
      &:hover:after {
        z-index: 10;
        animation: fill 1s forwards;
        opacity: 1;
      }
      &::after,
      &::before,
      & {
        transition: all 0.2s;
      }

      &:after,
      &::before {
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: ".";
        color: transparent;
        background: #e34f25;
        height: 3px;
      }

      &:hover:after {
        width: 100%;
      }

      &:after {
        text-align: left;
        content: ".";
        margin: 0;
        opacity: 0;
      }
      &.active {
        &::after {
          opacity: 1;
          width: 100%;
        }
      }
    }

    @media (max-width: 767.95px) {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    // &:hover {
    //   color: #000;
    //   border-bottom: 3px solid red;
    // }

    &.active {
      color: #000;
      //   &::after {
      //     opacity: 1;
      //     width: 100%;
      //   }
    }
  }
}

.logo-header {
  width: 200px;
}
