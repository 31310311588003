.price-list {
  min-height: calc(100vh - 240px);
}

#price-list-card {
  max-width: 900px;
  margin: 0 auto;

  & > div {
    background-color: rgb(248, 248, 248);
    &:nth-child(2n) {
      background-color: #fff;
    }
  }
}
