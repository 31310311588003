#jumbotron {
  background-color: #e9ecef;
}

.jumbotron {
  padding-top: 40px;
  background-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
}

.image {
  background-image: url("../assets/background-image.jpg");
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 170px);
}

.home-image-text {
  font-size: 1.25rem;
  font-weight: 600;
}

.title {
  font-size: 4rem;
  font-weight: 600;
}

.height {
  height: 2000px;
}

.home-card-header {
  background-color: #0d66be;
}
