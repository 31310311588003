.services {
  min-height: calc(100vh - 476px);
  .services-ul {
    list-style-type: disc;

    li {
      margin-bottom: 15px;
    }
  }

  .list-group-item.active {
    color: #fff;
    background-color: #6c757d;
    border-color: rgba(0, 0, 0, 0.125);
  }
}
