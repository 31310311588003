.contact {
  max-width: 900px;
  min-height: calc(100vh - 240px);
  margin: 0 auto;
}

#contact-info {
  ul {
    li {
      border: none;
    }
  }
  i {
    margin-right: 15px;
  }
  a {
    color: #000;
    text-decoration: none;
  }
}
