footer {
  .copyright {
    padding: 10px 0;
    background-color: rgb(26, 26, 26);
  }

  a {
    text-decoration: none;
    color: #000;
  }

  i {
    margin-right: 15px;
  }

  .footer-link {
    color: #fff;
  }
}
